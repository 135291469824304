import React from 'react';
import Layout from '../components/layout';
import Seo from '../components/seo';
import Image from '../components/image';
import { motion } from 'framer-motion';
import { CircleChevronRightFill } from 'akar-icons';

const OurPeoplePage = () => {
    const titlestrokeani = {
        duration: 1.65,
        ease: [0.19, 1.0, 0.22, 1.0],
        delay: 1,
    };

    return (
        <Layout>
            <Seo
                title="Our People"
                description="Our People at Quokka Finance"
            />
            <div className="app-title">
                <div className="wrapper">
                    <motion.div
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true }}
                        transition={{
                            duration: 0.45,
                            delay: 0.45,
                            ease: [0.23, 0.46, 0.4, 1],
                        }}
                        variants={{
                            visible: { opacity: 1, y: 0 },
                            hidden: { opacity: 0, y: -15 },
                        }}
                    >
                        <div className="title">
                            <h1>Our People</h1>
                        </div>
                    </motion.div>
                </div>
                <div className="titlestroke">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="100%"
                        viewBox="0 0 1983.911 218.409"
                    >
                        <motion.path
                            d="M7.57 7.57s427.09 139.916 918.524 188.554c355.142 35.149 744.9 22.1 1028.069-128.2"
                            fill="none"
                            stroke="#c2d83d"
                            strokeLinecap="round"
                            strokeWidth="12"
                            initial={{ pathLength: 0 }}
                            animate={{ pathLength: 1 }}
                            transition={titlestrokeani}
                        />
                    </svg>
                    <motion.div
                        initial={{ offsetDistance: '0%', scale: 2.5 }}
                        animate={{ offsetDistance: '100%', scale: 1 }}
                        transition={titlestrokeani}
                    />
                </div>
            </div>
            <div className="app-people">
                <div className="wrapper">
                    <motion.div
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true }}
                        transition={{
                            duration: 0.85,
                            delay: 0.45,
                            ease: [0.23, 0.46, 0.4, 1],
                        }}
                        variants={{
                            visible: { opacity: 1, y: 0 },
                            hidden: { opacity: 0, y: -15 },
                        }}
                    >
                        <div className="ourteam">
                            <div className="teamfull">
                                <div className="teamfull__img">
                                    <Image
                                        src="q-matt.png"
                                        alt="Matthew Banks"
                                    />
                                </div>
                                <div className="teamfull__txt">
                                    <h2>Matthew Banks</h2>
                                    <h3>Director</h3>
                                    <p>
                                        <strong>Phone:</strong> 0413 050 717
                                        <br />
                                        <strong>Email:</strong>{' '}
                                        matthewbanks@quokkafinance.com.au
                                    </p>
                                    <p>
                                        15+ years in Equipment Finance,
                                        Marketing &amp; Sales. Executive
                                        Director Wildcat Resources Ltd
                                        (ASX:WC8), Non-Executive Director Rumble
                                        Resources Ltd (ASX:RTR).
                                    </p>
                                </div>
                            </div>
                            <div className="teamfull">
                                <div className="teamfull__img">
                                    <Image src="q-ken.png" alt="Ken Jagger" />
                                </div>
                                <div className="teamfull__txt">
                                    <h2>Ken Jagger</h2>
                                    <h3>Director</h3>
                                    <p>
                                        <strong>Phone:</strong> 0404 128 751
                                        <br />
                                        <strong>Email:</strong>{' '}
                                        kenjagger@quokkafinance.com.au
                                    </p>
                                    <p>
                                        15+ years in Equipment Finance,
                                        including Credit &amp; Sales Executive
                                        functions for Commonwealth Bank of
                                        Australia, Halifax Bank of Scotland
                                        &amp; G.E. Past Chief Executive Officer
                                        ClearVue Technologies Ltd (ASX:CPV),
                                        past Director of Emerald Capital, past
                                        Associate Director of General Electric
                                        Capital.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </motion.div>
                </div>
            </div>
            <div className="app-quantum">
                <div className="wrapper">
                    <motion.div
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true }}
                        transition={{
                            duration: 0.85,
                            delay: 0.45,
                            ease: [0.23, 0.46, 0.4, 1],
                        }}
                        variants={{
                            visible: { opacity: 1, y: 0 },
                            hidden: { opacity: 0, y: -15 },
                        }}
                    >
                        <div className="contacttxt">
                            <div>
                                <h2>Quantum Business Finance</h2>
                                <a
                                    href="https://www.quantumbusiness.com.au/"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <Image src="quant-logo.svg" alt="Quantum" />
                                </a>
                            </div>
                        </div>
                    </motion.div>
                    <motion.div
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true }}
                        transition={{
                            duration: 0.85,
                            delay: 0.45,
                            ease: [0.23, 0.46, 0.4, 1],
                        }}
                        variants={{
                            visible: { opacity: 1, y: 0 },
                            hidden: { opacity: 0, y: -15 },
                        }}
                    >
                        <div className="quantumitem">
                            <p>
                                <strong>
                                    Quantum Business Finance is Australia's best
                                    connected and resourced Equipment Finance
                                    Broking firm.
                                </strong>
                            </p>
                            <p>
                                Their market position guarantees they get
                                preferential interest rates on the full range of
                                equipment finance facilities from every major
                                bank and specialised lender in the market.
                            </p>
                            <p>
                                They provide Motor Vehicle and Equipment Finance
                                to individuals in business, and to all business
                                types from sole traders through to listed
                                companies.
                            </p>
                            <p>
                                No matter how big or small your business, they
                                will provide you with a portfolio of lenders
                                with a diverse range of tailored products, and
                                we'll make their lenders fight for your
                                business.
                            </p>
                            <p>
                                <a
                                    href="https://www.quantumbusiness.com.au/"
                                    target="_blank"
                                    rel="noreferrer"
                                    className="btn"
                                >
                                    Visit Website{' '}
                                    <CircleChevronRightFill
                                        strokeWidth={2}
                                        size={35}
                                    />
                                </a>
                            </p>
                        </div>
                    </motion.div>
                </div>
            </div>
        </Layout>
    );
};

export default OurPeoplePage;
